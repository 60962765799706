<template>
    <div class="cuf-wrapper">
        <Breadcrumbs
            :items="items"
            class="cuf-breadcrumbs"
        />
        <h1 class="cuf-header">{{ $t('scholarshipCUF') }}</h1>
        <p class="cuf-text cuf-italic">{{ $t('cufItalicText') }}</p>
        <div v-html="$t('cufTextForMaster')" class="cuf-text"></div>
        <p class="cuf-tex">{{$t('cufText1')}}</p>
        <p class="cuf-text_number first-row cuf-text">
            <span class="list-number">1</span>
            <span>{{$t('cufText11')}}</span>
        </p>
        <p class="cuf-text_number cuf-text">
            <span class="list-number">2</span>
            <span>{{$t('cufText12')}}</span>
        </p>
        <p v-if="$t('cufText13')" class="cuf-text_number cuf-text">
            <span class="list-number">3</span>
            <span>{{$t('cufText13')}}</span>
        </p>
        <div v-html="$t('cufText2')" class="cuf-text"></div>
        <p class="cuf-text cuf-text_title">{{$t('cufText3')}}</p>
        <p class="cuf-text_number first-row cuf-text">
            <span class="list-number">1</span>
            <span v-html="$t('cufText31')"></span>
        </p>
        <p class="cuf-text_number cuf-text">
            <span class="list-number">2</span>
            <span v-html="$t('cufText32')"></span>
        </p>
        <br>
        <p class="cuf-text">{{ $t('cufFinished') }}</p>
        <p v-html="$t('cufPartners')" class="cuf-text cuf-text_partners"></p>
    </div>
</template>

<script>
import Breadcrumbs from '@/components/breadcrumbs';

export default {
    name: 'cuf-page',
    components: {
        Breadcrumbs,
    },
    data: () => ({
        items: [
            {
                text: 'home',
                href: '/'
            },
            {
                text: 'boursesEtudes',
                href: '#',
            },
            {
                text: 'scholarshipCUF',
                href: '#',
            }
        ]
    }),
}
</script>

<style lang="scss" scoped>
.cuf-wrapper {
    max-width: 706px;
    .cuf-header {
        margin-top: 25px;
        font-weight: 900;
        font-size: 36px;
        line-height: 47px;
    }
    .cuf-text, .cuf-tex {
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;

        &_number {
            display: flex;
            margin-bottom: 4px;
        }

        &_title {
            font-weight: bold;
            font-size: 22px;
            line-height: 34px;
            margin-top: 40px;
        }

        &_partners {
            margin-top: 40px;
        }
    }
    .list-number {
        margin-right: 15px;
    }
    .first-row {
        margin-top: 15px;
    }
    .cuf-italic {
        font-style: italic;
        margin: 25px 0 40px;
    }
}

</style>
<style>
</style>